// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    pageOne: path(ROOTS_DASHBOARD, '/main')
  },
  gallery: {
    root: path(ROOTS_DASHBOARD, '/gallery'),
    all: path(ROOTS_DASHBOARD, '/gallery/all-gallery'),
    add: path(ROOTS_DASHBOARD, '/gallery/add-new-gallery')
  },
  blogs: {
    root: path(ROOTS_DASHBOARD, '/blogs'),
    all: path(ROOTS_DASHBOARD, '/blogs/all-blogs'),
    add: path(ROOTS_DASHBOARD, '/blogs/add-new-blog')
  },
  clients: {
    root: path(ROOTS_DASHBOARD, '/clients'),
    all: path(ROOTS_DASHBOARD, '/clients/all-clients'),
    add: path(ROOTS_DASHBOARD, '/clients/add-new-client')
  },
  invoices: {
    root: path(ROOTS_DASHBOARD, '/invoices'),
    all: path(ROOTS_DASHBOARD, '/invoices/all-invoices'),
    add: path(ROOTS_DASHBOARD, '/invoices/add-new-invoice')
  },
  products: {
    root: path(ROOTS_DASHBOARD, '/products'),
    all: path(ROOTS_DASHBOARD, '/products/all-products'),
    add: path(ROOTS_DASHBOARD, '/products/add-new-product')
  },
  orders: {
    root: path(ROOTS_DASHBOARD, '/orders'),
    all: path(ROOTS_DASHBOARD, '/orders/order-management')
  },
  productCategory: {
    root: path(ROOTS_DASHBOARD, '/product-category'),
    all: path(ROOTS_DASHBOARD, '/product-category/all-product-categories')
  },

  leads: {
    root: path(ROOTS_DASHBOARD, '/leads'),
    all: path(ROOTS_DASHBOARD, '/leads/all-leads')
  },
  category: {
    root: path(ROOTS_DASHBOARD, '/category'),
    all: path(ROOTS_DASHBOARD, '/category/all-blogs-category')
  },
  tags: {
    root: path(ROOTS_DASHBOARD, '/tags'),
    all: path(ROOTS_DASHBOARD, '/tags/all-tags')
  },
  faqs: {
    root: path(ROOTS_DASHBOARD, '/faqs'),
    all: path(ROOTS_DASHBOARD, '/faqs/all-faqs')
  },
  config: {
    root: path(ROOTS_DASHBOARD, '/config'),
    all: path(ROOTS_DASHBOARD, '/config/complete-config')
  }
};
